import { formatedDateItem } from "@/_helpers/funciones";
function arrayObjectIndexOf(myArray, searchTerm, property) {
  for (var i = 0, len = myArray.length; i < len; i++) {
    if (myArray[i][property] === searchTerm) return i;
  }
  return -1;
}

function SoloNumeros(e) {
  var regex = new RegExp('^[0-9.,]+$');
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

export default {
  methods: {
    arrayObjectIndexOf,
    SoloNumeros,
  },
  computed: {},
};


//Funcion para bulkCargos del select
async function mountedBulkCargo() {
  this.$http
    .get('BulkCargo-list')
    .then((response) => {
      this.itemsBulkCargoSelect = response.data.data;
      const cargoTemporal = {
        TpCargoName: 'TODOS',
        TpCargoId: 'N/A',
      };
      this.itemsBulkCargoSelect = [cargoTemporal, ...this.itemsBulkCargoSelect];

      if (!this.editModal) {
        this.TpCargoId = response.data.data[0].TpCargoId;
      }
    })
    .finally(() => {
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Funcion computed para formatear los datos del select ###
function computedBulkCargoSelect() {
  if (this.itemsBulkCargoSelect.length > 0) {
    return this.itemsBulkCargoSelect.map((item) => {
      if(item.TpCargoId == 'N/A')
        return {
          label: this.$t('label.all'),
          value: item.TpCargoId,
        };
      else 
        return {
          label: item.TpCargoName,
          value: item.TpCargoId,
        };
    });
  }
}



//### Funcion computed para formatear los datos del select ###
function computedBulkCargoSelectModal() {
  let defaultSelectOption = {
    label: this.$t('label.toSelect'),
    value: '',
  };
  if (this.itemsBulkCargo.length > 0) {
    return [
      defaultSelectOption,
      ...this.itemsBulkCargo.map((item) => {
        return {
          label: item.TpCargoName,
          value: item.TpCargoId,
        };
      })
    ];
  } else {
    return [defaultSelectOption];
  }
}

function resetForm(){
  this.collapse = false;
  this.showModal = false;
  this.TpCargoId = '';
  this.selectAll = false;
  this.resetSelectAll = false;
  this.isEdit = false;
  this.$v.$reset();
}

export const TableIndexMixin = {
  methods: {
    mountedBulkCargo
  },
  computed:{
    computedBulkCargoSelect
  }
}

export const ModalCargaGranelMixin ={
  methods:{
    formatedDateItem,
    resetForm,
  },
  computed:{
    computedBulkCargoSelect: computedBulkCargoSelectModal
  }
}